import Vue from "vue";

import App from './App';
import {
	router
} from './router';
import axios from 'axios';
import https from './https';
import QS from 'qs'; //用来解决vue中post请求(详情);
import 'vant/lib/index.less'
import 'lib-flexible'
Vue.prototype.$axios = axios;
Vue.prototype.qs = QS;
Vue.prototype.https = https;
new Vue({
	router,
	el: '#app',
	render: h => h(App)
});
