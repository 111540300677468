import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '*',
    redirect: '*'
  },
  {
    name: 'abc/', 
    component: () => import('./view/code2'),
    meta: {
      title: '电脑信息',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  {
    name: ':id', 
    component: () => import('./view/code'),
    meta: {
      title: '电脑信息',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  
  // {
  //   name: 'code/:id(\\w+)',
  //   component: () => import('./view/code'),
  //   meta: {
  //     title: '电脑信息',
  //     auth: false // 如果此路由需要微信授权请设置为true,默认为false
  //   }
  // }
  
]

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '')
})

const router = new Router({ routes })

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

export { router }
